import React from 'react'
import "./SoftSkills.css"
import { Grid } from '@mui/material'
import '../../../src/App.css'

export const SoftSkills = ({room,otherSkills,otherSkillsComments, questions}) => {

    return (
        <Grid item className='wrapper'>
            <Grid className='soft-skill-wrapper  break-stop' >
                <Grid container justifyContent="center" textAlign="center" className='font-style-header-soft-skill soft-skill-header-600'>
                    Soft Skills
                </Grid>
                <div className='soft-skill-header-outer'>
                    <div className='soft-skill-header font-style-header-soft-skill'>
                        Soft Skills
                    </div>
                </div>
                <div className='soft-skill-content'>
                    {
                        otherSkills &&
                        <p className='font-style-soft-skill'><strong>Confidence:</strong> {otherSkillsComments['Confidence'][otherSkills['Confidence']]}</p>
                    }
                    {
                        otherSkills &&
                        <p className='font-style-soft-skill'><strong>Communication Skills:</strong> {otherSkillsComments['Communication Skills'][otherSkills['Communication Skills']]}</p>
                    }
                    {
                        otherSkills &&
                        <p className='font-style-soft-skill'><strong>Pressure handling:</strong> {otherSkillsComments['Pressure handling'][otherSkills['Pressure handling']]}</p>
                    }
                </div>
            </Grid>
            <Grid className='psycological-skill-wrapper  break-stop' >
                <Grid justifyContent="center" textAlign="center" alignItems="center" className='psycological-skill-header-600 font-style-header-soft-skill'>
                    Analytical Skills
                </Grid>
                <div className="psycological-skill-content">
                    {
                        otherSkills &&
                        <p className='font-style-soft-skill'><strong>Approach & Attitude:</strong> {otherSkillsComments['Approach & Attitude'][otherSkills['Approach & Attitude']]}</p>
                    }
                    {
                        otherSkills &&
                        <p className='font-style-soft-skill'><strong>Problem Solving:</strong> {otherSkillsComments['Problem solving'][otherSkills['Problem solving']]}</p>
                    }
                    {
                        otherSkills &&
                        <p className='font-style-soft-skill'><strong>Result Oriented:</strong> {otherSkillsComments['Result oriented'][otherSkills['Result oriented']]}</p>
                    }
                </div>
                <div className='psycological-skill-header-outer'>
                    <div className='psycological-skill-header font-style-header-soft-skill'>
                        Analytical Skill
                    </div>
                </div>

            </Grid>

            {room && room.candidate_experience> 10 && 
               <Grid className='soft-skill-wrapper  break-stop' >
                    <Grid container justifyContent="center" textAlign="center" className='font-style-header-soft-skill soft-skill-header-600'>
                    Leadership/Managerial Skills
                    </Grid>
                    <div className='soft-skill-header-outer'>
                        <div className='soft-skill-header font-style-header-soft-skill'>
                        Leadership Skills
                        </div>
                    </div>
                    <div className='soft-skill-content'>
                        {
                            otherSkills &&
                            <p className='font-style-soft-skill'><strong>Decision Making:</strong> {otherSkillsComments['Decision making'][otherSkills['Decision making']]}</p>
                        }
                        {
                            otherSkills &&
                            <p className='font-style-soft-skill'><strong>Team Management:</strong> {otherSkillsComments['Team management'][otherSkills['Team management']]}</p>
                        }
                        {
                            otherSkills &&
                            <p className='font-style-soft-skill'><strong>Accountability:</strong> {otherSkillsComments['Accountability'][otherSkills['Accountability']]}</p>
                        }
                    </div>
                </Grid>
            }
            
            <div className='pagebreak'></div>
            {(questions && Object.keys(questions).length >0 ) && (
                <>
                    <Grid className='screening-ques-wrapper'>
                        <Grid className='screening-ques-header font-style-header-screening'>
                            Screening Questions
                        </Grid>
                        <Grid className='ques-ans-wrapper'>
                            {questions && Object.keys(questions).map((value,index) =>{
                                return (
                                    <div className='break-stop'>
                                        <Grid className='ques font-style-soft-skill' style={{overflowWrap:'anywhere'}}>
                                            {index+1}.{value}
                                        </Grid>
                                        <Grid className='ans font-style-soft-skill' style={{overflowWrap:'anywhere'}}>
                                            {questions[value]}
                                        </Grid>
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>            
                    <div className='pagebreak'></div>
                </>
            )}
        </Grid >
    )
}
