import '../App.css';

import { Report } from './../components/Report/Report';
import { CircularGraph } from "./../components/mandatory-optional-skills-graph/CircularGraph"
import { CodeSnippet } from "./../components/CodeSnippet/CodeSnippet"
import { DetailedFeedBack } from './../components/DetailedFeedBack/DetailedFeedBack';
import { SoftSkills } from './../components/SoftSkill/SoftSkills';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../utils/urls';
import percent0 from "../images/percent0.svg"
import percent10 from "../images/percent10.svg"
import percent20 from "../images/percent20.svg"
import percent30 from "../images/percent30.svg"
import percent40 from "../images/percent40.svg"
import percent50 from "../images/percent50.svg"
import percent60 from "../images/percent60.svg"
import percent70 from "../images/percent70.svg"
import percent80 from "../images/percent80.svg"
import percent90 from "../images/percent90.svg"
import percent100 from "../images/percent100.svg"

const Feedback = () => {

    let { uuid } = useParams();

    const [room, setRoom] = useState(null);
    const [roomDetails, setRoomDetails] = useState(null);
    const [mandatorySkills, setMandatorySkills] = useState(null);
    const [optionalSkills, setOptionalSkills] = useState(null);
    const [otherSkills, setOtherSkills] = useState(null);
    const [mandatorySkillsComments, setMandatorySkillsComments] = useState(null);
    const [optionalSkillsComments, setOptionalSkillsComments] = useState(null);
    const [questions, setQuestions] = useState(null);

    useEffect(()=>{
        fetch(`${apiUrl}/v1/feedback/${uuid}`).then(async (res) => {
        // fetch(`https://interview.incruiter.com/api/v1/feedback/c2cd9862e36540e720944d3e46ffb05fba9e51e4e1b90bb0327b`).then(async (res) => {
            var response = await res.json();
            var newResponse = {
              room:response.data
            };
            // newResponse.push({
            //   "room":response.data
            // });
            // console.log('newResponse',newResponse);
            response = newResponse;
            setRoom(response);
            setRoomDetails(JSON.parse(response.room.other_details));
            
            setMandatorySkills(JSON.parse(response.room.interview_feedback.skills))
            setMandatorySkillsComments(JSON.parse(response.room.interview_feedback.skills_comments))
            
            setOptionalSkills(JSON.parse(response.room.interview_feedback.skills_optional))

            let optional_comments = JSON.parse(response.room.interview_feedback.skills_optional_comments);
            let optional_comments_is_null = true;
            if(optional_comments){
                Object.keys(optional_comments).map(key =>{
                    if(optional_comments[key]!=null){
                        optional_comments_is_null = false;
                    }
                });
            }
            if(!optional_comments_is_null){
                setOptionalSkillsComments(optional_comments)
            }
            else{
                setOptionalSkillsComments(null);
            }



            
            var otherSkillsTmp = JSON.parse(response.room.interview_feedback.other_ratings);
            console.log('otherSkillsTmp',otherSkillsTmp);
            setOtherSkills(otherSkillsTmp);

            setQuestions(JSON.parse(response.room.interview_feedback.questions))
        });
    },[uuid]);

    
    const newInterviewFeedbackPointsImg = (rating) => {
        if(rating == 0){
            return percent0;
        }
        else if(rating == 1){
            return percent10;
        }
        else if(rating == 2){
            return percent20;
        }
        else if(rating == 3){
            return percent30;
        }
        else if(rating == 4){
            return percent40;
        }
        else if(rating == 5){
            return percent50;
        }
        else if(rating == 6){
            return percent60;
        }
        else if(rating == 7){
            return percent70;
        }
        else if(rating == 8){
            return percent80;
        }
        else if(rating == 9){
            return percent90;
        }
        else if(rating == 10){
            return percent100;
        }
        else{
          return percent0;
        }

    }

    const getRatingFromOtherSkills = (skill,rating) =>{
      switch (skill) {
        case "Confidence":
          if(rating == "Inadequate Confidence") return 2;
          else if(rating == "Low Self-assurance") return 4;
          else if(rating == "Mildly Confident") return 6;
          else if(rating == "Bold & Confident") return 8;
          else if(rating == "Extremely Confident") return 10;
          else return 0;
          break;
      
        case "Communication Skills":
          if(rating == "Inadequate") return 2;
          else if(rating == "Poor") return 4;
          else if(rating == "Avg.. Comm. skills") return 6;
          else if(rating == "Decent Communication Skills") return 8;
          else if(rating == "Excellent Comm. Skill") return 10;
          else return 0;
          break;
      
        case "Pressure handling":
          if(rating == "Inefficient") return 2;
          else if(rating == "Vulnerable") return 4;
          else if(rating == "Moderate") return 6;
          else if(rating == "Capable") return 8;
          else if(rating == "Competent") return 10;
          else return 0;
          break;
      
        case "Approach & Attitude":
          if(rating == "Extremely Low") return 2;
          else if(rating == "Poor") return 4;
          else if(rating == "Moderate") return 6;
          else if(rating == "Positive") return 8;
          else if(rating == "Extremely Positive") return 10;
          else return 0;
          break;
    
        case "Problem solving":
          if(rating == "Extremely Low") return 2;
          else if(rating == "Substandard") return 4;
          else if(rating == "Moderate") return 6;
          else if(rating == "Problem Solver") return 8;
          else if(rating == "Excellent") return 10;
          else return 0;
          break;
      
        case "Result oriented":
          if(rating == "Extremely Low") return 2;
          else if(rating == "Lacks Focus") return 4;
          else if(rating == "Passable") return 6;
          else if(rating == "Highly Enthusiastic") return 8;
          else if(rating == "Goal Driven") return 10;
          else return 0;
          break;
      
        default:
          break;
      }
    }

    const otherSkillsComments = {
        "Confidence": {
            "Inadequate Confidence":"Less likely to be confident about one’s own abilities. Not very likely to believe in one's ability to achieve desired objectives and may not always try beat one's own performance expectations. Less likely to compete with others to prove one's capabilities. Not very likely to come across as a sociable, confident and assertive individual.",
            "Low Self-assurance":"Somewhat likely to have confidence in their own knowledge, skills, and abilities of only 10–20%.",
            "Mildly Confident":"Somewhat likely to have confidence in one’s own skills, abilities and knowledge. Moderately likely to be confident while meeting new people and working towards achieving results. May at times be able to maintain a positive attitude regardless of what others think or do. Somewhat likely to display a propensity for self-acceptance and self-adequacy. May occasionally come across as assertive and advocate one's point of view to bring oneself and clients to a common consensus.",
            "Bold & Confident":"Likely to have confidence in one’s own skills, abilities and knowledge. Less likely to be confident while meeting new people and working towards achieving results. May be able to maintain a positive attitude regardless of what others think or do. Likely to display a propensity for selfacceptance and self-adequacy. May often come across as assertive and advocate one's point of view to bring oneself and clients to a common consensus.",
            "Extremely Confident":"Likely to be confident about one’s own abilities. Likely to believe in one's ability to achieve desired objectives and may try beat one's own performance expectations. Likely to compete with others to prove one's capabilities. Likely to come across as a sociable, confident and assertive individual",
            "Over Confident":"Candidate is most likely to be overly confident or dismissive of others' opinions. he/she lacks openness to feedback and different perspectives in order to work effectively as part of a team. He should actively listen to others and be more open to constructive criticism."
        },
        "Communication Skills":{
            "Inadequate":"Somewhat likely to have no communication etiquette or skills, creating a detrimental effect on your business and bottom line",
            "Poor":"Somewhat likely to have a few communication skills; however, not suitable for dealing with clients. He/she might face trouble while communicating effectively in groups",
            "Avg.. Comm. skills":"Moderately likely to be able to structure one's thoughts and communicate in a clear, concise and accurate manner. May occasionally try various methods of communication and modify one's communication style according to the people that one interacts with. Moderately likely to be a good listener and may occasionally participate in conversations whenever necessary. Somewhat likely to work towards improving one's communication skills in order to put across one's point of view effectively",
            "Decent Communication Skills":"Likely to be able to structure one's thoughts and communicate in a clear, concise and accurate manner. May often try various methods of communication and modify one's communication style according to the people that one interacts with. Likely to be a good listener and may often participate in the conversations whenever necessary. Likely to work towards improving one's communication skills in order to put across one's point of view effectively.",
            "Excellent Comm. Skill":"Likely to have very strong effective communication skills to deal with even international clients fluently and holds great ability to explains tough issues in a way that is clear to the rest of the team and direct reports"
        },
        "Pressure handling":{
            "Inefficient":"Somewhat likely to have no capabilities to deliver work on-time during emergencies",
            "Vulnerable":"Somewhat likely to have 20-30% capabilities to accomplish the projects on-time during the workpressure phase.",
            "Moderate":"Somewhat likely to deal effectively with work pressure and gets stressed and worried somewhat easily. May at times be able to keep oneself positive and maintain an optimistic attitude towards life in adverse situations. Moderately likely to keep oneself composed and think clearly to utilize time and resources effectively in demanding and challenging situations. Somewhat likely to demonstrate a tendency to depend upon others when one is emotional and stressed.",
            "Capable":"Somewhat likely to handle 80-90% of your  unexpected workflows.",
            "Competent":"You can completely rely on the one to oversee all the unexpected work pressure before deadlines, helping to maintain an exceptional client experience."
        },
        "Approach & Attitude":{
            "Extremely Low":"The candidate has a tendency to be disruptive after difficult decisions have been announced, he might be incompetent to maintain a positive attitude in the workplace regardless of what others think or do.",
            "Poor":"His/Her demeanor can be unapproachable and this negatively impacts the morale of his team but occasionally delivers the work using a strategic approach. Most of the time, the one gets easily frustrated, which develops a negative attitude toward the work.",
            "Moderate":"Sometimes he is quick to congratulate coworkers and builds trust within his team.",
            "Positive":"He/She is adept at maneuvering around any obstacles that are thrown at him. He finishes his work on time and with accuracy. He holds adequate skills and capabilities to create  strategic plans & managing the entire workflow witha positive approach.",
            "Extremely Positive":"His/Her attitude is an asset to his management skills. He continually builds people up. He deals with difficult situations with incredible posture and focuses on the positive. He/She knows all ins and outs of implementing cutting-edge strategies & well-qualified to work with full dedication. You don't need to invest a lot on  the training program."
        },
        "Problem solving":{
            "Extremely Low":"The candidate is unlikely to effectively analyze a scenario and choose among many possible solutions to a particular problem in any complicated situation. He/she is unlikely to have skills like common sense, intuition and reasoning.",
            "Substandard":"One is familiar with problem-solving formulating strategies but incompetent in its practical implementation. Most likely fails to explain the rationale behind solution to a problem",
            "Moderate":"Somewhat likely to identify potential problems and issues or investigate them in greater depth. May occasionally put in efforts to analyze a problem, identify its causes or choose the best solution after contemplating available information and alternatives. May at times be able to utilize necessary resources to overcome challenges in an effective manner. May at times be able to identify risk areas or potential obstacles and take appropriate steps to deal with them.",
            "Problem Solver":"Most likely uses a variety of techniques to solve problems, and is willing to try new approaches. Puts in extra time to ensure that problems are solved completely and able to easily evaluate the available information and resources easily and demonstrates effective leadership skills when solving group problems. ",
            "Excellent":"Likely to identify potential problems and issues or investigate them in greater depth. May analyze a problem, identify its causes or choose the best solution to a problem after contemplating available information and alternatives. Out-of-box strategic problem-solving skills to address any challenging situation."
        },
        "Result oriented":{
            "Extremely Low":"Likely inconsistent in defining goals and objectives and he/she is not able to actively work towards achieving results on time",
            "Lacks Focus":"Is unwilling to claim responsibility for missed goals, Is easily distracted and disinterested in focusing to achieve performance goals",
            "Passable":"May occasionally be able to actively work towards achieve results on time. Somewhat likely to encourage others to work with dedication and commitment and put in one's best to complete a task. Moderately likely to understand the consequences of one's actions on the organization. Somewhat likely to be enthusiastic while working on challenging tasks and may at times demonstrate a sense of urgency to accomplish goals",
            "Highly Enthusiastic":"Likely to demonstrate the ability to work actively to achieve results on time. Likely to work with dedication and commitment and put in one's best to complete a task. Likely to be concerned with consequences of one's work on the organization as a whole. Likely to be enthusiastic while working on challenging tasks and may demonstrate a sense of urgency to accomplish goals. Likely to set high-performance standards for self and others, and try to make efforts to maintain the same",
            "Goal Driven":"Likely to be intrinsically motivated to work on given tasks wholeheartedly. Likely to consistently work towards delivering desired results and may ensure that whatever one takes up is completed successfully. Likely to try out new strategies to accomplish challenging goals, and may remain focused on achieving one's targets, despite obstacles. Likely to pay attention to all aspects of the job to ensure high quality output is maintained.",
            "Over Aggressive":"Candidate is more likely to be overly aggressive or confrontational. It's important for the candidate to be mindful of how he/she are perceived by others in order to work effectively as part of a team. it would be great if the candidate become more mindful of his tone and body language, and to consider the perspectives and feelings of others when communicating."
        },
        "Decision making":{
            "Bad Decision Maker":"He/she may not be able to think rationally and thus may not be able to contemplate between positive and negative situations.",
            "Dysfunctional":"He/She may have a very little knowledge of how to make wise decisions, therefore you will need to invest in training programmes to teach them this ability.",
            "Moderate":"Somewhat likely to take inputs of each team member before making decisions that are important for them. May occasionally take into account possible consequences of the decisions being made",
            "Quite Decisive":"Most likely Remains calm, especially under stress & comes to reasonable conclusions based on information presented, He/ShebBalances swift decision-making, with the ability to analyze the many angles to a story",
            "Extremely Decisive":"Likely to make well-informed, effective or timely decisions in order to avoid negative repercussions. May take into account possible consequences of the decisions being made"
        },
        "Team management":{
            "Extremely Lousy":"Sometimes sets an unreasonably high expectation for his/her team and cansSends mixed signals to the team regarding goals and day-to-day activities",
            "Poor Admin. & Control":"Not likely to take interest in the work of team members or coach them on how to execute tasks effectively. May not always encourage them to develop their abilities or provide constructive suggestions for the same. May rarely train team members at a personal level. Less likely to make sure that the potential of each member has been utilized in a productive way and may not always assign tasks basis one's capabilities or skills.",
            "Decent Synergism":"Often likely to effectively manage team. Occasionally guides team efforts, tracks team progress, sometimes anticipates roadblocks and at times changes course as needed to achieve team goals. Occasionally sets and communicates clear goals for the team. Partially able to understand the individual differences and provides personal coaching, mentoring, training and uses learning and development approaches at times. Occasionally motivates and encourages team members to learn. Somewhat likely to be attuned to one's and others' emotions and may be capable of understanding and listening to other's problems. At times, provides meaningful feedback to team members to keep them on track toward common goals.",
            "People Manager":"Likely to take interest in the work of team members and coach them on how to execute tasks effectively. May often encourage them to develop their abilities and provide constructive suggestions for the same. May train team members at a personal level. Likely to make sure that the potential of each member has been utilized in a productive way and may assign tasks basis one's capabilities or skills.",
            "Highly Efficient":"Likely to provide constructive feedback to team members in a timely manner. Likely to share one's useful learnings and experiences with team members to help them develop improve their skills and abilities. May be able to support one's team by coaching and mentoring them effectively and providing sufficient opportunities to help them improve upon their areas of development. One is excellent team leader qualities!! Provides comprehensive feedbacks to team members to keep them on track toward common goals."
        },
        "Accountability":{
            "Extremely Unaccountable":"Not likely to hold oneself accountable or accept responsibility for the actions taken by oneself and one's team members. Less likely to make sure one's own and team's tasks are completed on time. May rarely be able to hold oneself accountable for one's own and team's failures and successes. May not always be able to encourage team members to fulfil the commitments made",
            "Low Accountability":"Not very likely to take responsibility for one's own actions or decisions. Not very likely to take one's duties and responsibilities seriously. May seldom be able to understand one's mistakes and may rarely rectify them immediately. Less likely to hold oneself or others accountable to complete the assigned task and may rarely execute one's work without seeking any guidance from others",
            "Somewhat Accountable":"Moderately likely to hold oneself accountable and at times accept responsibility for the actions taken by oneself and one's team members. Somewhat likely to make sure one's own and team's tasks are completed on time. May at times be able to hold oneself accountable for one's own and team's failures and successes. May sometimes be able to encourage team members to fulfil the commitments made.",
            "Answerable":"Likely to take responsibility for one's own actions and decisions. Likely to take ownership for delivering good quality work and adhering to timelines. May be able to take decisions for self and the team. May be able to understand one's mistakes and may rarely rectify them immediately. Likely to have a good ability to complete all the assigned tasks on time without continuous supervision.",
            "Extremely Reliable":"One is committed to delivering the work even before the deadlines. The candidate is good to move forward when it comes to holding accountable for the workflows. "
        }
    }



    return (
      <div className="App">
        {room ? 
            <>
                <Report room={room} roomDetails={roomDetails} skills={mandatorySkills} optionalSkills={optionalSkills} newInterviewFeedbackPointsImg={newInterviewFeedbackPointsImg} getRatingFromOtherSkills={getRatingFromOtherSkills}  otherSkills={otherSkills} />
                <CircularGraph room={room?.room} skills={mandatorySkills} optionalSkills={optionalSkills} newInterviewFeedbackPointsImg={newInterviewFeedbackPointsImg} />
                <DetailedFeedBack room={room?.room} skillComments={mandatorySkillsComments} optionalSkillComments={optionalSkillsComments} skills={mandatorySkills} optionalSkills={optionalSkills} />
                <SoftSkills otherSkillsComments={otherSkillsComments} room={room?.room} otherSkills={otherSkills} questions={questions}/>
                <CodeSnippet room={room?.room} />
            </>
            :
            <>
                <div class="fullpage-loader d-flex">
                    <div class="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </>
        }
      </div>
    );
}

export default Feedback;